import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

import grid from './gridStore'
import product from './productStore'
import i18n from "ThemeSrc/js/vue/i18n";

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
    key: 'app-navigation',
    storage: window.localStorage,
    reducer: (state) => ({
        locale: state.locale
    })
})

export default new Vuex.Store({
    state: {
        locale: null,
        modalContact: {
            status: false,
            subject: false,
            source: false
        }
    },
    getters: {
        contactIsOpen(state) {
            return state.modalContact.status
        },
        contactSubject(state) {
            return state.modalContact.subject
        },
        contactSource(state) {
            return state.modalContact.source
        },
        displayProduct(state, getters, rootState) {
            return !!(getters.contactSource !== 'header' && (state.product.pageProduct && state.product.pageProduct.id))
        }
    },
    actions: {
        async updateLocale ({ commit, dispatch, state }, { locale }) {
            i18n.locale = locale
            commit('setLocale', { locale })
        },
        openModalContact ({ commit }, payload = { subject: false, source: false }) {
            commit('setModalContactStatus', true)
            commit('setModalSubject', payload.subject)
            commit('setModalSource', payload.source)
        },
        closeModalContact ({ commit }) {
            commit('setModalContactStatus', false)
            commit('setModalSubject', false)
            commit('setModalSource', false)
        }
    },
    mutations: {
        setLocale (state, { locale }) {
            state.locale = locale
        },
        setModalContactStatus (state, status) {
            state.modalContact.status = status
        },
        setModalSubject (state, subject) {
            state.modalContact.subject = subject
        },
        setModalSource (state, source) {
            state.modalContact.source = source
        }
    },
    modules: {
        grid,
        product
    },
    plugins: [vuexLocal.plugin]
})
