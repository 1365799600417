import {SMOOTH_SCROLL} from "ThemeSrc/js/utils/Scroll";
import {Observer} from "ThemeSrc/js/utils/Observer";
import gsap from 'gsap'

const LAYOUT = {
	items: null,
	triggers: null,
	backColors: null
}

export const observer = new Observer()

/**
 * Layout control
 */
export const updateLayout = () => {
	if (LAYOUT.items && LAYOUT.triggers) {
		// pour chaque element "layout-influençable"
		for (const layoutAble of LAYOUT.items) {
			const layoutAbleRect = layoutAble.getBoundingClientRect()
			// pour chaque element "layout-changer"
			for (const layoutChanger of LAYOUT.triggers) {
				const layoutChangerRect = layoutChanger.getBoundingClientRect()
				const overlap = (
					!(layoutAbleRect.left >= layoutChangerRect.right || layoutAbleRect.right <= layoutChangerRect.left)
					&&
					!(layoutAbleRect.top >= layoutChangerRect.bottom || layoutAbleRect.bottom <= layoutChangerRect.top)
				)
				if (overlap) {
					if (layoutAble.dataset.layoutItem !== layoutChanger.dataset.layout) {
						if (layoutAble.dataset.layoutDebug === 'true') {
							console.log(`Overlap : ${overlap}`)
							console.log(`Layout changer for : ${layoutChanger.dataset.layout}`, layoutChanger)
						}
						layoutAble.dataset.layoutItem = layoutChanger.dataset.layout
					}
					break
				} else {
					if (layoutAble.dataset.layoutDebug === 'true') {
						console.log(`Overlap : ${overlap}`)
						console.log(`Layout changer for : ${layoutChanger.dataset.layout}`, layoutChanger)
					}
					layoutAble.dataset.layoutItem = ''
				}
			}
		}
	}
}

export const updateLayoutItems = () => {
	LAYOUT.items = document.querySelectorAll('[data-layout-item]')
	LAYOUT.triggers = document.querySelectorAll('[data-layout]')
	LAYOUT.backColors = document.querySelectorAll('[data-back-color]')

	// back color
	observer.clearObserved()
	if (LAYOUT.backColors.length) {
		for (const hasBackItem of LAYOUT.backColors) {
			const timeLine = gsap.timeline({paused: true})
			timeLine.fromTo(document.body, {
				backgroundColor: '#FFF'
			}, {
				backgroundColor: hasBackItem.dataset.backColor
			})
			observer.observe(hasBackItem, (ratio) => {
				timeLine.progress(ratio)
			})
		}
	}
}

/**
 * Init events
 */
export const initEvents = () => {

	/**
	 * Bind events
	 */
	SMOOTH_SCROLL.on('scroll', () => {

		updateLayout()
	})
}


/**
 * Init
 */
export const init = () => {
	updateLayoutItems()
	updateLayout()
	initEvents()
}

/**
 * Update
 */
export const update = () => {
	updateLayoutItems()
	updateLayout()
}

// window.updateLayout = () => { updateLayout() }