<template>
	<div
		id="cta-fixed"
		ref="block"
		data-layout-item
	>
		<div
			@click="click"
			id="cta-fixed__wrapper"
			ref="wrapper"
		>
			<p class="text--label mb-0">{{ page.title }}</p>
			<icon id="arrow" width="30" height="30" view-box="0 0 30 30"/>
		</div>
	</div>
</template>
<script>
import gsap from 'gsap'
import {trackShowContact} from "ThemeSrc/js/utils/Track";
import {mapActions} from "vuex";
// import {updateLayout} from "ThemeSrc/js/components/Layout";
// import Navigation from "ThemeSrc/js/navigation";
// import {updateLayout} from "ThemeSrc/js/components/Layout";

export default {
	name: 'ToolbarContact',
	data() {
		return {
			displayStatus: false,
			pageStyle: 'project',
			pageStyles: {
				project: {
					title: this.$t('Vous avez un projet ?'),
					modalParams: {
						subject: 'contactEstimate'
					}
				},
				estimate: {
					title: this.$t('Demander un devis'),
					modalParams: {
						subject: 'contactEstimate'
					}
				}
			}
		}
	},
	computed: {
		page() {
			return this.pageStyles[this.pageStyle]
		}
	},
	methods: {
		...mapActions('product', [
			'updatePageProduct'
		]),
		click() {
			this.$store.dispatch('openModalContact', this.page.modalParams)
			trackShowContact('cta fixed', this.page.title)
		},
		checkDisplay() {
			if (['single-collection','single-project'].some(className => document.body.classList.contains(className))) {
				this.displayStatus = true
			}
		},
		display() {
			gsap.to(this.$refs.block, {
				height: this.$refs.block.scrollHeight,
				duration: 1.5,
				ease: 'Power4.easeOut'
			})
		},
		hide() {
			gsap.to(this.$refs.block, {
				height: 0,
				duration: 1.5,
				ease: 'Power4.easeOut'
			})
		},
		updateContactStyle() {
			// update style
			const pageStyle = document.querySelector('[data-contact-style]')
			this.pageStyle = pageStyle && pageStyle.dataset.contactStyle ? pageStyle.dataset.contactStyle : 'project'

			// update product
			const pageProduct = document.querySelector('[data-contact-product]')
			if (pageProduct) this.updatePageProduct( {id: parseInt(pageProduct.dataset.contactProduct)} )
		},
		handleScroll(scroll) {
			const footer = document.querySelector('#footer')
			if (!footer) return
			const footerRect = footer.getBoundingClientRect()
			const toolbarRect = this.$refs.block.getBoundingClientRect()
			const footerToolbarDiff = (toolbarRect.top + toolbarRect.height) - footerRect.top

			if (footerToolbarDiff > 0)
			{
				gsap.set(this.$refs.wrapper, {
					y: footerToolbarDiff,
				})
			} else
			{
				gsap.set(this.$refs.wrapper, {
					y: 0,
				})
			}
		}
	},
	watch: {
		displayStatus(newValue) {
			if (newValue) {
				this.display()
			} else {
				this.hide()
			}
		}
	},
	beforeMount() {
		this.updateContactStyle()
	},
	mounted() {

		gsap.set(this.$refs.block, {
			height: 0
		})

		this.checkDisplay()

		this.$root.$on('NAVIGATE_OUT', () => {
			this.displayStatus = false
		})

		this.$root.$on('NAVIGATE_IN', () => {
		})

		this.$root.$on('NAVIGATE_END', () => {
			this.updateContactStyle()
			this.checkDisplay()
		})

		this.$root.$on('SCROLL', ({scroll}) => {
			this.handleScroll(scroll)
		})
	}
}
</script>