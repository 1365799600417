import axios from "axios";
import {notify} from "ThemeSrc/js/components/Notification";

const AXIOS_CANCEL = axios.CancelToken
const AXIOS_REQUESTS = {}

/**
 * @param {string} url
 * @param {object} params
 * @param {boolean} cancellable
 * @return {Promise<Object>}
 */
export async function get (url, params = {}, cancellable = true) {

    // url = `/${appjs.currentLocale}${url}`

    if (AXIOS_REQUESTS[url] && cancellable) {
        AXIOS_REQUESTS[url].cancel()
    }
    AXIOS_REQUESTS[url] = AXIOS_CANCEL.source()

    const response = await axios.get(url, {
        params: params,
        cancelToken: AXIOS_REQUESTS[url].token,
        headers : {'X-Requested-With': 'XMLHttpRequest'}
    }).catch((e) => {
        delete AXIOS_REQUESTS[url]
        if (axios.isCancel(e)) {
            throw 'Canceled'
        }
        throw new ApiError(e.response.data, e.response.status)
    })

    delete AXIOS_REQUESTS[url]

    if (response.status === 200) {
        if (typeof response.data.status === 'undefined' || response.data.status) {
            if (response.data.message) {
                new feedback(response.data, response.status, 'success')
            }
            if(response.data.reload) {
                window.location.reload()
            } else if (response.data.redirect) {
                window.location = response.data.redirect
            }
            return response.data
        } else {
            throw new feedback(response.data, response.status, 'error')
        }
    }

    throw new ApiError(response.data, response.status)
}

/**
 * @param {string} url
 * @param {object} data
 * @return {Promise<Object>}
 */
export async function post (url, data = {}) {

    // url = `/${appjs.currentLocale}${url}`

    const response = await axios.post(url, data, {
        headers : {
            'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            'Content-Type': 'multipart/form-data',
            'X-Requested-With': 'XMLHttpRequest'
        }
    }).catch((e) => {
        throw new ApiError(e.response.data, e.response.status)
    })

    if (response.status === 200) {
        if (typeof response.data.status === 'undefined' || response.data.status) {
            if (response.data.message) {
                return new feedback(response.data, response.status, 'success')
            }
            if(response.data.reload) {
                window.location.reload()
            } else if (response.data.redirect) {
                window.location = response.data.redirect
            }
            return response
        } else {
            return new feedback(response.data, response.status, 'error')
        }
    }

    throw new ApiError(response.data, response.status)
}

export class feedback {

    constructor (data, status, type = 'info') {
        this.data = data
        this.status = status
        this.type = type
        this.notify()
    }

    notify () {
        let message = ''

        if (this.data.message) message += `${this.data.message}`
        else if (this.data.errors) message += `${Object.values(this.data.errors).join(', ')}`
        else message = 'Error'

        if (message) {
            notify( message, {type: this.type})
        }
    }
}

export class ApiError {

    constructor (data, status) {
        this.data = data
        this.status = status
        if (process.env.APP_ENV === 'dev') this.notify()
    }

    notify () {
        const error = `[${this.status}] ${this.data.detail}`
        notify( error, {type: 'error'})
    }
}