import {SplitText} from 'gsap/SplitText';
import gsap from "gsap";

gsap.registerPlugin(SplitText);

export const setPageAnimation = (timeLine, position) =>
{
    const pageItems = document.querySelectorAll('[data-to-show]')
    for (const item of pageItems)
    {
        setPropertiesAnimations(timeLine, item, position)

        if (item.dataset.toShow === 'cut')
        {
            setCutAnimation(timeLine, item, position)
        }
        else if (item.dataset.toShow === 'cutInside')
        {
            setCutInsideAnimation(timeLine, item, position)
        }
        else if (item.dataset.toShow === 'fade')
        {
            setFadeAnimation(timeLine, item, position)
        }
        else if (item.dataset.toShow === 'cascade')
        {
            item.classList.add('is-animated')
            timeLine.from(item.children, {
                opacity: 0,
                y: -10,
                ease: 'Power4.easeInOut',
                duration: 1.5,
                stagger: 0.075,
                onComplete: () => {
                    item.classList.remove('is-animated')
                }
            }, position)
        }
    }
}

const setPropertiesAnimations = (timeLine, node, position) =>
{
    if (node.dataset.toProperty)
    {
        for (const propertyObject of JSON.parse(node.dataset.toProperty))
        {
            const property = propertyObject.property
            timeLine.fromTo(node, {
                [property]: propertyObject.from
            }, {
                [property]: propertyObject.to,
                ease: "Power4.easeInOut",
                duration: 1.5
            }, position)
        }
    }
}

const setCutAnimation = (timeLine, node, position) =>
{

    timeLine.call(() =>
    {
        node.classList.add('is-animated')
        node.split = new SplitText(node, {type: 'lines,words'})
        gsap.set(node.split.lines, {
            overflow: 'hidden',
        })
        gsap.set(node.split.words, {
            yPercent: 100
        })
    }, null, 0)

    timeLine.call(() =>
    {
        const timeLine = gsap.timeline()

        timeLine.to(node.split.words, {
            yPercent: 0,
            ease: "Power4.easeInOut",
            duration: 1.5,
            stagger: 0.075,
            onComplete: () => {
                node.classList.remove('is-animated')
                // split.revert()
            }
        }, 0)

        timeLine.call(() => {
            node.classList.add('end-animated')
        }, null, '>-0.75')

    }, null, position)
}

const setFadeAnimation = (timeLine, node, position) =>
{
    const paragraphs = node.children

    timeLine.from(paragraphs, {
        opacity: 0,
        ease: "Power4.easeInOut",
        duration: 1.5,
        stagger: 0.01
    }, position)
}

const setCutInsideAnimation = (timeLine, node, position) =>
{
    const paragraphs = node.children

    for (const p of paragraphs)
    {
        // const split = new SplitText(p, {type: 'lines,words'})
        const split = nestedLinesSplit(p, {type: 'lines,words'})

        timeLine.call(() => {
            p.classList.add('is-animated')
        }, null, 0)

        timeLine.set(split.lines, {
            overflow: 'hidden',
        }, 0)

        timeLine.set(split.words, {
            yPercent: 100
        }, 0)

        timeLine.to(split.words, {
            yPercent: 0,
            ease: "Power4.easeInOut",
            duration: 1.5,
            stagger: 0.01,
            onComplete: () => {
                p.classList.remove('is-animated')
            }
        }, position)
    }
}

/**
 * Nested SplitText wordkaround
 * @url https://greensock.com/forums/topic/18243-split-text-confused/?tab=comments#comment-84134
 *
 * @param target
 * @param vars
 */
function nestedLinesSplit(target, vars) {
    let split = new SplitText(target, vars),
        words = (vars.type.indexOf("words") !== -1),
        chars = (vars.type.indexOf("chars") !== -1),
        insertAt = function (a, b, i) {
            let l = b.length,
                j;
            for (j = 0; j < l; j++) {
                a.splice(i++, 0, b[j]);
            }
            return l;
        },
        children, child, i;

    if (typeof(target) === "string") {
        target = document.querySelectorAll(target);
    }
    if (target.length > 1) {
        for (i = 0; i < target.length; i++) {
            split.lines = split.lines.concat(nestedLinesSplit(target[i], vars).lines);
        }
        return split;
    }

    children = (words ? split.words : []).concat(chars ? split.chars : []);
    for (i = 0; i < children.length; i++) {
        children[i]._protect = true;
    }

    children = split.lines;
    for (i = 0; i < children.length; i++) {
        child = children[i].firstChild;
        if (!child._protect && child.nodeType !== 3) {
            children[i].parentNode.insertBefore(child, children[i]);
            children[i].parentNode.removeChild(children[i]);
            children.splice(i, 1);
            i += insertAt(children, nestedLinesSplit(child, vars).lines, i) - 1;
        }
    }
    return split;
}