import 'ThemeSrc/js/components/Events'
import initMenu from "ThemeSrc/js/components/Menu";
import {init as initForms, update as updateForms} from "ThemeSrc/js/components/Form";
import {init as initTabs, update as updateTabs} from "ThemeSrc/js/components/Tabs";
import {init as initInfiniteSlide, update as updateInfiniteSlide} from "ThemeSrc/js/components/InfiniteSlide";
import {init as initLayout, update as updateLayout} from 'ThemeSrc/js/components/Layout'
import {initActions} from 'ThemeSrc/js/utils/Scroll'

const COMPONENTS = [
	{
		init: initLayout,
		update: updateLayout,
	},
	{
		init: initForms,
		update: updateForms,
	},
	{
		init: initTabs,
		update: updateTabs,
	},
	{
		init: () => initInfiniteSlide('.words-slide'),
		update: () => updateInfiniteSlide('.words-slide'),
	},
	{
		init: () => initActions(),
		update: () => {},
	}
]

export const menuApp = new initMenu('#menu-wrapper')

/**
 * Handle components init
 */
export const initComponents = () => {
	for (const component of COMPONENTS) {
		component.init()
	}
}

/**
 * Handle components updates
 */
export const updateComponents = () => {
	for (const component of COMPONENTS) {
		component.update()
	}
}

initComponents()