import gsap from 'gsap'
import {SMOOTH_SCROLL} from "ThemeSrc/js/utils/Scroll";

export const PARAMS = {
	currentScroll: 0,
	savedScroll: 0,
	upDelta: 50,
	header: document.querySelector('#header'),
	visible: true
}

/**
 * Header controls
 */
const headerControls = (scrollObject) =>
{
	const scroll = scrollObject.scroll
	const direction = scrollObject.direction

	if (document.body.classList.contains('nav-open')) return

	if (scrollObject.scroll.y > 0) {
		document.body.classList.add('scrolled')
	} else {
		document.body.classList.remove('scrolled')
		toggleHeader(true)
	}

	if (direction === 'down') {
		document.body.classList.add('scroll-down')
		document.body.classList.remove('scroll-up')
		PARAMS.savedScroll = scroll.y
		if (scroll.y > 0) {
			toggleHeader(false)
		}
	}
	else if (direction === 'up') {
		if ((scroll.y + PARAMS.upDelta) < (PARAMS.savedScroll + 0) || scroll.y < 150) {
			document.body.classList.add('scroll-up')
			document.body.classList.remove('scroll-down')

			toggleHeader(true)
		}
	}
}

/**
 * Bind events
 */
SMOOTH_SCROLL.on('scroll', (scroll) => {
	headerControls(scroll)
})

/**
 * @param {boolean} show
 */
const toggleHeader = (show = false) => {

	if (PARAMS.visible !== show)
	{
		const headerRect = PARAMS.header.getBoundingClientRect()

		gsap.to(PARAMS.header, {
			top: show ? 0 : -(headerRect.height),
			ease: 'Power3.easeInout'
		})

		PARAMS.visible = show
	}
}