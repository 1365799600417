import 'ThemeSrc/js/blocks/Header' // exec permanante
import 'ThemeSrc/js/blocks/Footer' // exec permanante

const BLOCKS = [
	{
		loaded: false,
		test: () => document.querySelector('#block-slider__slides'),
		module: () => import(
			/* webpackChunkName: "async-block-homeSlider" */ 'ThemeSrc/js/blocks/HomeSlider'
			)
	},
	{
		loaded: false,
		test: () => document.querySelector('.block-slidepages__slide'),
		module: () => import(
			/* webpackChunkName: "async-block-homeSlider" */ 'ThemeSrc/js/blocks/SlidePages'
			)
	},
	{
		loaded: false,
		test: () => document.querySelector('[data-load-content]'),
		module: () => import(
			/* webpackChunkName: "async-block-archive" */ 'ThemeSrc/js/blocks/Archive'
			)
	},
	{
		loaded: false,
		test: () => document.querySelector('#project'),
		module: () => import(
			/* webpackChunkName: "async-block-project" */ 'ThemeSrc/js/blocks/Project'
			)
	},
	{
		loaded: false,
		test: () => document.querySelector('#material'),
		module: () => import(
			/* webpackChunkName: "async-block-material" */ 'ThemeSrc/js/blocks/Material'
			)
	},
	{
		loaded: false,
		test: () => document.querySelector('#collection'),
		module: () => import(
			/* webpackChunkName: "async-block-collection" */ 'ThemeSrc/js/blocks/Collection'
			)
	},
	{
		loaded: false,
		test: () => document.querySelector('#contact'),
		module: () => import(
			/* webpackChunkName: "async-block-contact" */ 'ThemeSrc/js/blocks/Contact'
			)
	},
	{
		loaded: false,
		test: () => document.querySelector('#block-relation'),
		module: () => import(
			/* webpackChunkName: "async-block-relation" */ 'ThemeSrc/js/blocks/Relation'
			)
	},
	{
		loaded: false,
		test: () => document.querySelector('.content-block__advantages'),
		module: () => import(
			/* webpackChunkName: "async-block-advantages" */ 'ThemeSrc/js/blocks/Advantages'
			)
	}
]


/**
 * Handle modules async imports
 */
export const initBlocks = () => {
	for (const block of BLOCKS) {
		if (block.test()) {
			if (!block.loaded) {
				block.module().then((module) => {
					module.init()
					block.loaded = true
				})
			} else {
				block.module().then(module => module.update())
			}
		}
	}
}

initBlocks()
