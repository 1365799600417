<template>
	<aside
		id="block-contact"
		ref="modal"
		v-if="openStatus"
		@mousemove="handleMove"
		@mouseleave="handleMoveLeave"
		@click="close"
	>
		<div
			id="block-contact__holder"
			ref="holder"
			@mousemove.stop
			@click.stop
			@mouseenter="handleMoveLeave"
			@mouseleave="handleMoveEnter"
		>
			<div v-if="!success" id="block-contact__header" class="d-flex align-items-center justify-content-between">
				<div class="d-flex align-items-center">
					<span
						@click="setModalSubject(false)"
						v-if="selectedSubject"
						class="page__back text--inherit is-simple mb-0 mr-s-3"
					>
						<template v-if="responsive.s.min">{{ $t('Retour') }}</template>
					</span>
					<p class="text--label text--inherit mb-0">{{ subject ? (responsive.sm.min ? subject.title : subject.shortTitle) : $t('Contact') }}</p>
					<p v-if="displayProduct && selectedSubject === 'contactEstimate'" class="text--label text--plain text--inherit ml-4 mb-0">{{ pageProduct.name }}</p>
				</div>
				<div @click="close" class="cursor-pointer">
					<icon id="cross"/>
				</div>
<!--					<div>-->
<!--						<span @click="close" class="link">{{ $t('close') }}</span><br>-->
<!--						<p v-if="hasProgression" class="mt-2 mb-0 text-legal">{{ $t('contact.progression') }}</p>-->
<!--					</div>-->
<!--					<div class="btn btn&#45;&#45;actions btn&#45;&#45;icon">-->
<!--						<span v-if="responsive.s.min" class="text-secondary mr-0 pr-0">{{ $t('profile.yours') }}</span>-->
<!--						<span v-if="responsive.s.max" class="text-secondary mr-0 pr-0"><icon id="profile"/></span>-->
<!--						<span v-if="userProfile" @click="$store.dispatch('openModalProfile')" class="btn__profile mr-1">-->
<!--                            <icon id="cross" class-name="mr-1" width="10" height="10"></icon>-->
<!--                            {{ profileText }}-->
<!--                        </span>-->
<!--                        <span v-else @click="$store.dispatch('openModalProfile')">{{ $t('profile.none') }}</span>-->
<!--					</div>-->
			</div>
			<div
				id="block-contact__body"
				:class="{'d-flex flex-column justify-content-center': !selectedSubject}"
			>
				<template v-if="!selectedSubject">
					<p class="h2">{{ $t('Votre besoin ?') }}</p>
					<div class="form__subjects">
						<div
							v-for="s in subjects"
							@click="selectSubject(s)"
							:class="`contact-item form__subject ${s.context ? `is-${s.context}`:''}`"
						>
							<p class="text--label whitespace--normal pl-0 mb-0">
								{{ s.title }}
								<template v-if="displayProduct && s.component === 'contactEstimate'">| {{ pageProduct.name }}</template>
							</p>
							<icon id="arrow"/>
						</div>
					</div>
					<div
						v-if="contactInfos.link"
						class="wysiwyg mt-6"
					>
						<p class="h2">{{ $t('Nous trouver') }}</p>
						<p class="whitespace--normal" v-html="contactInfos.address">
<!--							<template v-if="contactInfos.email"><br><a :href="`mailto:${contactInfos.email}`">{{ contactInfos.email }}</a></template>-->
						</p>
						<a
							:href="contactInfos.link"
							class="btn btn--primary btn--outline"
						>
							{{ $t('En savoir plus') }}
						</a>
					</div>
				</template>
				<template v-else>
					<template v-if="!success">
						<component
							v-if="selectedSubject.length"
							@submit="submitForm"
							v-model="form.data"
							:errors="form.errors"
							:loading="loading"
							:is="selectedSubject"
							:contact-infos="contactInfos"
						/>
					</template>
					<template v-else>
						<div id="block-contact__success">
							<p class="h1 mb-6" v-html="$t('Merci pour votre demande&nbsp;!')"></p>
							<div v-if="!formConfirmation">
								<p>{{ $t('Notre équipe reviendra vers vous dans les plus brefs délais.') }}</p>
								<p>{{ $t('Très belle journée !') }}</p>
							</div>
							<div v-else v-html="formConfirmation"></div>
							<div class="d-flex flex-column align-items-start">
								<button class="btn btn--primary mb-3" @click="closeAfterSubmit">{{ selectedSubject === 'contactEstimate' ? $t('retour au catalogue') : $t('retourner au site') }}</button>
								<a @click="cleanFormData" href="/" class="btn btn--primary btn--outline">{{ $t("vers l'accueil") }}</a>
							</div>
						</div>
					</template>
				</template>
			</div>
		</div>
		<div id="block-contact__close" ref="closeButton"><icon id="cross"/></div>
	</aside>
</template>
<script>
import gsap from 'gsap'
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {postLead} from "ThemeSrc/js/api/contact";
import {trackSendLead} from "ThemeSrc/js/utils/Track";
import {get} from "ThemeSrc/js/api/api";
import {parseFormData} from "ThemeSrc/js/utils/Helper";

export default {
		name: 'ModalContact',
		components: {
			contactEstimate: () => import( /* webpackChunkName: "async-component-contactEstimate" */  "ThemeSrc/js/vue/components/contact/scenario/contactEstimate"),
			contactJob: () => import( /* webpackChunkName: "async-component-contactJob" */  "ThemeSrc/js/vue/components/contact/scenario/contactJob"),
			contactDefault: () => import( /* webpackChunkName: "async-component-contactDefault" */  "ThemeSrc/js/vue/components/contact/scenario/contactDefault"),
			contactMeet: () => import( /* webpackChunkName: "async-component-contactMeet" */  "ThemeSrc/js/vue/components/contact/scenario/contactMeet"),
		},
		watch: {
			openStatus(newValue) {
				this.$nextTick(() => {
					document.body.style.overflow = newValue ? 'hidden':''
					this.$root.$emit('TOGGLE_MODAL', {status: newValue})
					if (newValue) {
						this.open()
					}
				})
			},
			selectedSubject(newValue) {
				localStorage.formSubject = newValue
			},
			'form.data': {
				handler(newValue) {
					localStorage.formData = JSON.stringify(newValue)
				},
				deep: true
			},
			'form.errors': {
				handler(newValue) {
					this.$root.$emit('MODALCONTACT_FORM_ERROR', {errors: newValue})
				},
				deep: true
			}
		},
		data() {
			return {
				form: {
					data: {},
					errors: {}
				},
				loading: false,
				success: false,
				contactInfos: {},
				subjects: [
					{
						title: this.$t('Demande de devis'),
						shortTitle: this.$t('Devis'),
						component: 'contactEstimate'
					},
					{
						title: this.$t('Venir au showroom'),
						shortTitle: this.$t('Showroom'),
						component: 'contactMeet'
					},
					{
						title: this.$t("Postuler pour un emploi"),
						shortTitle: this.$t("Postuler"),
						component: 'contactJob'
					},
					{
						title: this.$t("Autres demandes"),
						shortTitle: this.$t("Autre"),
						component: 'contactDefault'
					}
				]
			}
		},
		computed: {
			...mapState('product', [
				'pageProduct'
			]),
            ...mapGetters('grid', [
                'responsive'
            ]),
			...mapGetters({
				displayProduct: 'displayProduct',
				openStatus: 'contactIsOpen',
				getSubject: 'contactSubject'
			}),
			selectedSubject() {
                return this.getSubject
			},
			subject() {
				return this.selectedSubject ? this.subjects.find( s => s.component === this.selectedSubject) : null
			},
			formConfirmation() {
				return this.selectedSubject ? this.contactInfos.formConfirmation[this.selectedSubject] : false
			}
		},
		methods: {
			...mapActions([
				'closeModalContact',
			]),
			...mapMutations([
				'setModalSubject'
			]),
			async submitForm() {
				this.loading = true

				const formData = parseFormData(this.form.data)
				formData.append('subject', JSON.stringify(this.subject))

				const returnData = await postLead(formData)
				trackSendLead(returnData.data.status, this.subject.title, returnData.data.message)
				if (returnData.data.status === true) {
					this.success = true
					localStorage.formData = ''
					localStorage.formSubject = ''
				} else {
					this.success = false
					this.form.errors = returnData.data.errors
				}
				this.loading = false
			},
			selectSubject(subject) {
				if (subject.component) {
					this.setModalSubject(subject.component)
				} else if (subject.url) {
					window.location = subject.url
				}
			},
			open() {
				this.$refs.modal.classList.add('open','on-open')
				gsap.set(this.$refs.holder, {
					xPercent: 100
				})
				gsap.to(this.$refs.holder, {
					xPercent: 0,
					ease: 'Expo.easeOut',
					onComplete: () => {
						this.$refs.modal.classList.remove('on-open')
					}
				})
			},
			cleanFormData() {
				this.form.data = {}
			},
			closeAfterSubmit() {
				this.cleanFormData()
				this.close()
			},
			close() {
				this.$refs.modal.classList.remove('open')
				this.handleMoveLeave()
				gsap.to(this.$refs.holder, {
					xPercent: 100,
					ease: 'Expo.easeIn',
					onComplete: () => {
						this.success = false
						this.closeModalContact()
					}
				})
			},
			handleMove(event) {
				this.$refs.closeButton.style = `top:${event.clientY - 24}px;left:${event.clientX - 24}px`
			},
			handleMoveLeave() {
				gsap.to(this.$refs.closeButton, {
					scale: 0,
					opacity: 0,
					duration: .5,
					ease: 'Expo.easeOut'
				})
			},
			handleMoveEnter() {
				gsap.to(this.$refs.closeButton, {
					scale: 1,
					opacity: 1,
					duration: .5,
					ease: 'Expo.easeInOut'
				})
			}
		},
		async mounted() {
			if (localStorage.formData) {
				this.form.data = JSON.parse(localStorage.formData)
			}
		},
		async created () {
			this.contactInfos = await get('/api/contact/getInfos')
		}
	}
</script>
<style scoped lang="scss">
	.cursor-pointer {
		cursor: pointer;
	}
</style>