import Vue from 'vue'
import VueI18n from "vue-i18n";

Vue.use(VueI18n)

const messages = {
    en: {
        buttons: {
            contact: 'Contact en',
            contactUs: 'Contact us',
            ra: 'See in AR',
            raSupport: 'Open the page on your mobile device'
        },
        cta: {
            default: 'Request a demo'
        },
        profile: {
            title: "Let's try to guide you, let's get to know each other",
            subtitle: "Choose the role that best suits you",
            default: "I don't recognize myself in these situations",
            none: "You are ?",
            yours: "Your profile",
        },
        contact: {
            title: "Comment pouvons-nous<br>vous aider&nbsp;?",
            back: "Retourner aux choix",
            submit: "Envoyer la demande",
            legal: "En envoyant le formulaire ci-dessus, vous autorisez l’entreprise BONE 3D à stocker et traiter les données personnelles soumises ci-dessus afin qu’elle vous fournisse le contenu demandé.",
            'Vos informations': 'Vos informations',
            'Choisissez les produits concernés': 'Choisissez les produits concernés',
            'Sélectionnez les produits' : 'Sélectionnez les produits',
            productsSelection : 'Aucun produit sélectionné | 1 produit sélectionné | {count} produits sélectionnés',
            andProductsSelection : ' | et un autre produit | et {count} autres produits',
            career: "Si vous souhaitez postuler à l’une de nos offres d’emploi",
            progression: "Votre progression est sauvegardée",
            demo: 'Demander une démo',
            estimate: 'Demander <br>un&nbsp;devis',
            contactUs: 'Nous contacter',
            open: 'Ouvrir le formulaire',
            success: {
                title: 'Demande envoyée !',
                subtitle: 'Merci pour votre message, votre demande a bien été envoyée.'
            }
        },
        close: 'Close'
    },
    fr: {
        buttons: {
            contact: 'Contact',
            contactUs: 'Contactez-nous',
            ra: 'Voir en réalité augmentée',
            raSupport: 'Ouvrez la page sur votre mobile'
        },
        cta: {
            default: 'Demander une démo'
        },
        profile: {
            title: "Essayons de vous orienter, faisons connaissance",
            subtitle: "Choisissez le rôle qui vous correspond le mieux",
            default: "Je ne me reconnais pas dans ces catégories",
            none: "Vous êtes ?",
            yours: "Votre profil",
        },
        contact: {
            title: "Comment pouvons-nous<br>vous aider&nbsp;?",
            back: "Retourner aux choix",
            submit: "Envoyer la demande",
            legal: "En envoyant le formulaire ci-dessus, vous autorisez l’entreprise BONE 3D à stocker et traiter les données personnelles soumises ci-dessus afin qu’elle vous fournisse le contenu demandé.",
            'Vos informations': 'Vos informations',
            'Choisissez les produits concernés': 'Choisissez les produits concernés',
            'Sélectionnez les produits' : 'Sélectionnez les produits',
            productsSelection : 'Aucun produit sélectionné | 1 produit sélectionné | {count} produits sélectionnés',
            andProductsSelection : ' | et un autre produit | et {count} autres produits',
            career: "Si vous souhaitez postuler à l’une de nos offres d’emploi",
            progression: "Votre progression est sauvegardée",
            demo: 'Demander une démo',
            estimate: 'Demander <br>un&nbsp;devis',
            contactUs: 'Nous contacter',
            open: 'Ouvrir le formulaire',
            success: {
                title: 'Demande envoyée !',
                subtitle: 'Merci pour votre message, votre demande a bien été envoyée.'
            }
        },
        form: {
            required: 'Ce champ est requis'
        },
        close: 'Fermer'
    }
}

const i18n = new VueI18n({
    locale: appjs.currentLocale,
    messages,
})

export default i18n