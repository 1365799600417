import {GRID_SIZES, maxSize, minSize} from "ThemeSrc/js/utils/Grid";

const state = {
    responsive: {},
}

const getters = {
    responsive (state) {
        return state.responsive
    }
}

const actions = {
    updateSizes ({ commit }) {
        const sizes = {}
        for (const range of GRID_SIZES) {
            sizes[range.size] = {
                max: maxSize(range.size),
                min: minSize(range.size)
            }
        }
        commit('setSizes', { sizes })
    }
}

const mutations = {
    setSizes (state, { sizes }) {
        state.responsive = sizes
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
