<template>
	<button
		@click="clickButton"
		:class="[buttonClass, additionalClass]"
		:data-layout-item="baseLayout"
	>
		<slot>
<!--			<template v-if="responsive.s.max">-->
<!--				<icon id="tel" width="22" height="22" view-box="0 0 22 22"/>-->
<!--			</template>-->
<!--			<template v-else>-->
<!--			</template>-->
			{{ 'contact' }}
		</slot>
<!--		<span v-if="$store.state['grid'].responsive.s.min">{{ $t('cta.default') }}</span>-->
<!--		<icon v-if="$store.state['grid'].responsive.s.max" id="envelop"/>-->
	</button>
</template>
<script>
import {trackShowContact} from "ThemeSrc/js/utils/Track";
import {mapGetters} from "vuex";

export default {
	name: 'ButtonContact',
	props: {
		place: String,
		baseLayout: String,
		additionalClass: String,
		buttonClass: {
			type: String,
			default: 'btn btn--primary btn--outline btn--icon'
		}
	},
	data() {
		return {
			showTel: false
		}
	},
	computed: {
		...mapGetters('grid', [
			'responsive'
		])
	},
	methods: {
		clickButton() {
			this.$store.dispatch('openModalContact', {source: 'header'})
			trackShowContact(this.place, this.$t('Contact'))
		}
	}
}
</script>