import gsap from 'gsap'

export const updateBreadcrumb = async (to) =>
{
    const pageBreadcrumb = document.querySelector('#header__nav .breadcrumb')
    const newBreadcrumb = to.page.body.querySelector('#header__nav .breadcrumb')

    // save breadcrumb to page object to handle Highway cache
    if (!to.page.breadcrumb) {
        to.page.breadcrumb = newBreadcrumb ? [...newBreadcrumb.children] : []
    }

    const newChildren = to.page.breadcrumb

    // no active bread on new page
    if (!newBreadcrumb)
    {
        gsap.to(pageBreadcrumb, {
            opacity: 0,
            duration: 0.45
        })

        return
    }
    else
    {
        gsap.to(pageBreadcrumb, {
            opacity: 1,
            duration: 0.7
        })
    }

    // no active bread on page
    if (!pageBreadcrumb)
    {
        const breadWrapper = document.querySelector('#header__nav #breadcrumb__wrapper')
        const fullBreadcrumb = to.page.body.querySelector('#header__nav #breadcrumb__wrapper')
        breadWrapper.appendChild(fullBreadcrumb.firstElementChild)

        gsap.set(newChildren, {opacity: 0})

        for (let i = 0; i < newChildren.length; i++)
        {
            const item = newChildren[i]
            await showItem(item)
        }

        return
    }

    const pageChildren = pageBreadcrumb.children
    const maxItems = Math.max(pageChildren.length, newChildren.length)

    // construct bread object
    const traitBread = []
    for (let i = 0; i < maxItems; i++)
    {
        const oldItem = pageChildren[i]
        const newItem = newChildren[i]

        const object = {
            oldName: getName(oldItem),
            newName: getName(newItem),
            old: oldItem,
            new: newItem,
            type: ''
        }

        if (!newItem)
        {
            object.type = 'remove'
        }
        else if (!oldItem)
        {
            object.type = 'add'
        }
        else if (getName(oldItem) === getName(newItem))
        {
            object.type = 'update'
        }
        else if (getName(oldItem) !== getName(newItem))
        {
            object.type = 'change'
        }

        traitBread.push(object)
    }

    // run transitions
    pageBreadcrumb.classList.add('is-animated')
    const items = newChildren.length >= pageChildren.length ? traitBread : traitBread.reverse()
    for (let i = 0; i < items.length; i++)
    {
        const item = items[i]
        // console.log(i, item.type, item.oldName, item.newName, i * 500)
        if (item.type === 'remove') {
            await hideItem(item.old)
        }
        else if (item.type === 'add')
        {
            await addItem(item.new, pageBreadcrumb)
        }
        else if (item.type === 'update')
        {
            item.old.parentNode.replaceChild(item.new, item.old)
        }
        else if (item.type === 'change')
        {
            await hideItem(item.old, item.new)
        }
    }
    pageBreadcrumb.classList.remove('is-animated')
}

const getName = (node) => {
    if (!node) return
    return node.querySelector('[itemprop="name"]').textContent
}

const hideItem = (item, newItem) =>
{
    return new Promise((resolve) =>
    {
        const timeLine = gsap.timeline({
            onStart: () => {
                item.classList.add('is-animated')
            }
        })

        timeLine.to(item, {
            opacity: 0,
            duration: 0.45
        }, 0)

        timeLine.addLabel('endHide', '>')

        if (newItem)
        {
            timeLine.call(() => {
                item.parentNode.replaceChild(newItem, item);
            }, null, 'endHide')


            timeLine.to(newItem, {
                opacity: 1,
                duration: 0.7,
                onStart: () => {
                    item.classList.remove('is-animated')
                }
            }, 'endHide')
        }
        else {
            timeLine.call(() => {
                item.remove()
            }, null, 'endHide')
        }

        timeLine.call(() => {
            resolve()
        }, null, '>-0.1')
    })
}

const addItem = (item, breadcrumb) =>
{
    return new Promise((resolve) => {
        gsap.set(item, {
            opacity: 0
        })
        breadcrumb.appendChild(item)
        gsap.to(item, {
            opacity: 1,
            duration: 0.7,
            onStart: () => {
                item.classList.remove('is-animated')
            },
            onComplete: () => {
                resolve()
            }
        })
    })
}

const showItem = (item) =>
{
    return new Promise((resolve) => {
        gsap.set(item, {
            opacity: 0
        })
        gsap.to(item, {
            opacity: 1,
            duration: 0.7,
            onStart: () => {
                item.classList.remove('is-animated')
            },
            onComplete: () => {
                resolve()
            }
        })
    })
}