import gsap from 'gsap'
import {SMOOTH_SCROLL} from "ThemeSrc/js/utils/Scroll";

const bindTabs = () => {

	const tabSections = document.querySelectorAll('[data-tab-section]')

	for (const tabSection of tabSections) {

		if (tabSection.classList.contains('tab-section-binded')) return

		tabSection.options = {
			...{
				padding: {
					bottom: 0
				},
				keepOpen: false
			},
			...JSON.parse(tabSection.dataset.tabSection)
		}

		const tabRows = tabSection.querySelectorAll('[data-tab-row]')
		// const triggers = tabSection.querySelectorAll('[data-tab-trigger]')
		// const contents = tabSection.querySelectorAll('[data-tab-content]')

		for (const tabRow of tabRows) {

			const trigger = tabRow.querySelector('[data-tab-trigger]')
			const content = tabRow.querySelector('[data-tab-content]')
			tabRow.trigger = trigger
			tabRow.content = content
			tabRow.options = tabSection.options
			prepareTab(tabRow)

			tabRow.open = () => { openTab(tabRow) }
			tabRow.close = () => { closeTab(tabRow) }

			trigger.addEventListener('click', (event) => {

				event.preventDefault()

				if (!tabSection.options.keepOpen) {
					if (tabSection.previousElementSibling
						&& tabSection.previousElementSibling.classList.contains('tab-section-binded'))
					{
						closeSectionTabs(tabSection.previousElementSibling)
					}

					if (tabSection.nextElementSibling
						&& tabSection.nextElementSibling.classList.contains('tab-section-binded'))
					{
						closeSectionTabs(tabSection.nextElementSibling)
					}
				}

				if (!tabRow.classList.contains('is-open')) {
					openTab(tabRow)
				} else {
					closeTab(tabRow)
				}

				if (!tabSection.options.keepOpen) {
					closeSectionTabs(tabSection)
				}
			})
		}

		SMOOTH_SCROLL.update()
		tabSection.classList.add('tab-section-binded')
	}
}

const prepareTab = (row) => {
	gsap.set(row.content, {height: 0, overflow: 'hidden'})
}

const closeTab = (row) => {
	gsap.to(row.content,{
		height: 0,
		duration: .5,
		ease: 'Circ.easeInOut',
		onStart: () => {
			row.content.style.height = row.content.scrollHeight + row.options.padding.bottom
			row.content.style.paddingBottom = 0
		},
		onUpdate: () => {
			SMOOTH_SCROLL.update()
		}
	})
	row.classList.remove('is-open')
}

const openTab = (row) => {
	gsap.to(row.content, {
		height: row.content.scrollHeight + row.options.padding.bottom,
		duration: .5,
		ease: 'Circ.easeInOut',
		onComplete: () => {
			row.content.style.height = ''
			row.content.style.paddingBottom = `${row.options.padding.bottom}px`
		},
		onUpdate: () => {
			SMOOTH_SCROLL.update()
		}
	})
	row.classList.add('is-open')
}

const closeSectionTabs = (tabSection) => {
	const tabRows = tabSection.querySelectorAll('[data-tab-row]')
	for (const r of tabRows) {
		closeTab(r)
	}
}

/**
 * Init
 */
export const init = () => {
	bindTabs()
}

/**
 * Update
 */
export const update = () => {
	const tabSections = document.querySelectorAll('[data-tab-section].tab-section-binded')
	for (const section of tabSections)
	{
		section.classList.remove('tab-section-binded')
	}
	bindTabs()
}
