import gsap from 'gsap'
import {Linear} from 'gsap'
import {wrapAll} from "ThemeSrc/js/utils/Helper";
import {matchSize} from "ThemeSrc/js/utils/Grid";

const initSlide = (node) => {

	if (node.classList.contains('is-binded')) return

	// setup variables
	const spacer = matchSize('s') ? 30 : 60
	const duration = 45
	const wrapper = wrapAll(node.children)
	const children = wrapper.children
	const childrenCount = wrapper.children.length

	let lastScrollTop
	let scrollDir = true

	// prepare
	gsap.set(wrapper, { display: 'flex', position: 'relative', alignItems: 'center' })
	gsap.set(children, { marginRight: spacer })

	// clone nodes for loop
	for (let i = 0; i < childrenCount; i++) {
		const clone = children[i].cloneNode(true)
		clone.classList.add('clone--loop')
		wrapper.appendChild(clone)
	}

	const baseTotalWidth = wrapper.scrollWidth
	const targetWidth = window.innerWidth * 2
	// clone nodes
	if (baseTotalWidth < targetWidth) {
		const targetNumberChildren = Math.ceil(targetWidth / (baseTotalWidth/childrenCount))
		for (let i = childrenCount; i < targetNumberChildren; i++) {
			const clone = children[i - childrenCount].cloneNode(true)
			clone.classList.add('clone')
			wrapper.appendChild(clone)
		}
	}

	// animate
	// gsap.set(wrapper, { left: '100vw', visibility: 'visible' })
	// const gsapPrepare = gsap.to(wrapper, { left: '0', ease: 'Circ.easeOut', duration: 3, paused: true })
	const gsapSlide = gsap.to(wrapper, {
		x: '-' + (baseTotalWidth/2),
		ease: Linear.easeNone,
		duration: duration,
		paused: true,
		repeat: -1,
		modifiers: {
			x: function(x) {
				x = parseFloat(x) % baseTotalWidth
				return `${x}px`
			}
		},
		onComplete: () => {
			// update on scroll
			window.addEventListener('scroll', () => {
				const documentHeight = document.body.scrollHeight
				const windowHeight = window.innerHeight;
				const scrollTop = window.scrollY;

				if (scrollDir !== scrollTop < lastScrollTop) {
					scrollDir = scrollTop < lastScrollTop
				}

				const scrollDiff = Math.abs((scrollTop - lastScrollTop) / (documentHeight - windowHeight))
				const newProgress = Math.min(Math.max(0, gsapSlide.progress() + scrollDiff), 1)
				gsapSlide.progress(newProgress)

				lastScrollTop = scrollTop
			})
		}
	})

	// gsapPrepare.play()
	gsapSlide.play()

	node.classList.add('is-binded')
}

/**
 * Block
 *
 * @type function
 * @name clientsLogos
 */
export const init = (selector = '.infinite-slide') => {
	const nodes = document.querySelectorAll(selector)
	for (const node of nodes) {
		initSlide(node)
	}
}

/**
 * Update
 */
export const update = (selector = '.infinite-slide') => {
	init(`${selector}:not(.is-binded)`)
}