import Vue from 'vue';
import store from 'ThemeSrc/js/vue/store'
import i18n from 'ThemeSrc/js/vue/i18n'
import ButtonContact from "ThemeSrc/js/vue/components/ButtonContact";
import Icon from "ThemeSrc/js/vue/components/Icon";
import ModalContact from "ThemeSrc/js/vue/components/ModalContact";
import ButtonTel from "ThemeSrc/js/vue/components/ButtonTel";

import 'ThemeSrc/js/vue/directive'
import {mapActions, mapGetters} from "vuex";
import ToolbarContact from "ThemeSrc/js/vue/components/ToolbarContact";

Vue.component('icon', Icon)
Vue.component('button-contact', ButtonContact)
Vue.component('button-tel', ButtonTel)
Vue.component('modal-contact', ModalContact)
Vue.component('toolbar-contact', ToolbarContact)

Vue.filter('striptags', function (value) {
    const div = document.createElement('div')
    div.innerHTML = value
    return div.textContent || div.innerText || ''
})

export const app = new Vue({
    store,
    i18n,
    el: '#app',
    created () {
        this.updateSizes()
        window.addEventListener('resize', this.updateSizes)
    },
    mounted () {

    },
    computed: {
        ...mapGetters('grid', [
            'responsive'
        ])
    },
    methods: {
        ...mapActions('grid', [
            'updateSizes'
        ]),
        call (event, params) {
            this.$emit(event, params)
        },
        dispatch (type, payload) {
            this.$store.dispatch(type, payload)
        }
    }
})

/**
 * Bind DOM events
 */
document.addEventListener('click', function (event) {
    const pushEvent = event.target.closest('[data-push-event]')
    if (pushEvent) {
        event.preventDefault()
        const params = pushEvent.dataset.pushEvent.split('|')
        app.call(params[0], JSON.parse(params[1]))
    }
    const dispatchEvent = event.target.closest('[data-dispatch-event]')
    if (dispatchEvent) {
        event.preventDefault()
        const params = dispatchEvent.dataset.dispatchEvent.split('|')
        app.dispatch(params[0], JSON.parse(params[1]))
    }
})