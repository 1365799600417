<template>
	<button
		@click="click"
        :class="[buttonClass, {'pl-1 pr-1': !showTel}]"
		:data-layout-item="baseLayout"
	>
		<template v-if="showTel">
			{{ tel }}
		</template>
		<template v-else>
			<icon id="tel" width="22" height="22" view-box="0 0 22 22"/>
		</template>
	</button>
</template>
<script>
	import {trackShowTelEvent} from "ThemeSrc/js/utils/Track";
    import {maxSize} from "ThemeSrc/js/utils/Grid";

	export default {
		name: 'ButtonTel',
		props: {
			place: String,
			baseLayout: String,
			buttonClass: {
				type: String,
				default: 'btn btn--primary btn--outline btn--icon'
			},
			tel: {
				type: String,
				required: true
			}
		},
		data() {
			return {
				showTel: false
			}
		},
		computed: {
			displayedTel() {
				return this.showTel ? this.tel : 'Nous appeler'
			}
		},
		methods: {
			click() {
                if (maxSize('sm')) {
                    window.location = `tel:${this.tel}`
                } else {
				    if (this.showTel) return window.location = `tel:${this.tel}`
				    this.showTel = true
                }
				trackShowTelEvent(this.place)
			}
		}
	}
</script>