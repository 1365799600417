import 'whatwg-fetch'
import gsap from 'gsap'
import 'promise-polyfill/src/polyfill';
import {notify} from "ThemeSrc/js/components/Notification";
/**
 * Component
 * @name formComponent
 */

export const bindAjaxForm = async ( node ) => {

    await initRecaptcha()

    node = node || null;

    const options = {
        locale: false
    }

    const formClass = {
        ajaxPrefix: 'ajax-form-',
        fieldGroup: 'field__group',
        fieldPrefix: 'ajax-field-',
        fieldError: 'ajax-field-hold-error'
    };

    const bindForm = ( form ) => {

        if (form.appEventBinding) return;

        form.addEventListener('submit', (event) => {

            event.preventDefault()
            // window.components.front.loader(true)
            handleLoading(form)

            const data = new FormData(form)
            const action = form.action

            /**
             * Handle captcha
             */
            handleCheck().then(token => {

                data.append('recaptcha_token', token)
                if (options.locale) {
                    data.append('locale', document.querySelector('meta[name="current-locale"]').content)
                }

                /**
                 * Execute request normally
                 */
                window.fetch( action, {
                    method: 'POST',
                    headers: {
                        // 'Content-Type': 'application/json',
                        // 'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
                        'X-Requested-With': 'XMLHttpRequest'
                    },
                    body: data
                })
                    .then(res => res.json())
                    .then(response => {

                        // phpdebugbar.ajaxHandler.handle(response);
                        // window.components.front.loader(false)

                        if (response.status){
                            handleSuccess(form, response)
                        } else {
                            handleErrors(response, form)
                        }

                    })
                    .catch(err => {
                        console.warn('Erreur : '+action)
                        console.warn(err)
                    })
            })

        })

        form.appEventBinding = true
        form.classList.add(formClass.ajaxPrefix+'binded')
    }

    const handleCheck = async () => {
        return await checkCaptcha()
    }

    const handleLoading = ( form ) => {

        handleLoadingStart(form)

        form.classList.add(formClass.ajaxPrefix+'loading')
        form.classList.remove(formClass.ajaxPrefix+'error')

        let nodes = form.querySelectorAll('.'+formClass.fieldPrefix+'error' )
        nodes.forEach( ( node ) => {
            node.classList.remove(formClass.fieldPrefix+'error')
        })

        let errors = form.querySelectorAll('.'+formClass.fieldError )
        errors.forEach( ( node ) => {
            node.parentNode.removeChild(node)
        })
    }

    const handleLoadingStart = ( form ) => {
        const submit = form.querySelector('button[type="submit"]')
        const loaderLabel = document.createElement('span')
        loaderLabel.classList.add('loader-label')
        loaderLabel.appendChild(document.createTextNode('chargement...'))
        submit.oldWidth = submit.getBoundingClientRect().width
        submit.appendChild(loaderLabel)
        submit.classList.add('btn--loading')
        const timeLine = gsap.timeline()
        timeLine.set(loaderLabel, {
            position: 'absolute',
            left: '50%',
            top: '50%',
            opacity: 0,
            xPercent: -50,
            yPercent: -50
        },0)
        timeLine.to(loaderLabel, {
            opacity: 1,
            duration: .15,
            ease: 'Power3.easeOut'
        }, 0)
        timeLine.to(submit, {
            width: loaderLabel.offsetWidth + 60,
            duration: .15,
            ease: 'Power3.easeOut'
        }, 0)
    }

    const handleLoadingEnd = ( form ) => {
        const submit = form.querySelector('button[type="submit"]')
        const loaderLabel = submit.querySelector('span')
        const timeLine = gsap.timeline()
        timeLine.to(loaderLabel, {
            opacity: 0,
            duration: .15,
            ease: 'Power3.easeOut',
            onComplete: () => {
                submit.classList.remove('btn--loading')
            }
        }, 0)
        timeLine.to(submit, {
            width: submit.oldWidth,
            duration: .15,
            ease: 'Power3.easeOut'
        }, 0)
        timeLine.eventCallback('onComplete', () => {
            submit.style.width = ''
            loaderLabel.remove()
        })
    }

    const handleSuccess = ( form, response ) => {

        handleLoadingEnd(form)

        form.classList.add(formClass.ajaxPrefix+'success')
        form.classList.remove(formClass.ajaxPrefix+'loading')

        notify( response.message ? response.message : form.dataset.success, {type:'success'})
        form.reset()
        // self.components.front.contactActions.close()
        // setTimeout(function() {
        //     self.components.front.contactActions.initFormConditions()
        // },500)

        triggerGtmEvent('success', form)
    }

    const handleErrors = ( response, form ) => {

        handleLoadingEnd(form)

        form.classList.add(formClass.ajaxPrefix+'error')
        form.classList.remove(formClass.ajaxPrefix+'loading')

        const errors = response.errors

        if (errors.length < 1)
        {
            return notify( response.message, {type: 'error'})
        }

        let messages = []

        Object.keys(errors).forEach( ( id) => {

            let node = form.querySelector(`[name="${id}"]`)

            if (node)
            {
                let closest = node.closest('.'+formClass.fieldGroup)

                if (closest)
                {
                    closest.classList.add(formClass.fieldPrefix+'error')
                    // create node error
                    let errorNode = document.createElement('div')
                    errorNode.classList.add(formClass.fieldError)
                    errorNode.appendChild( document.createTextNode(errors[id]) )

                    // append error
                    closest.appendChild(errorNode)
                }
            }

            // push message
            messages.push(errors[id])
        })

        // notify
        notify( messages, {type:'error'})

        triggerGtmEvent('error', form)
    }

    const triggerGtmEvent = ( type, form ) => {
        const formData = new FormData(form)
        let data = {}
        for(const pair of formData.entries()) {
            if (pair[1]) data[pair[0]] = pair[1]
        }
        if(typeof dataLayer === 'object' && dataLayer) {
            dataLayer.push({'event': 'formSubmission', parameters: {type: type, data: data} });
        }
    }

    // bind form if node not null
    if( node ) bindForm( node );

    /**
     * Loop through forms at loading
     */
    let forms = document.querySelectorAll('form[data-form-ajax]')

    forms.forEach( ( form ) => {
        bindForm( form )
    })
}

const checkCaptcha = () => {
    return new Promise(function(resolve, reject){
        grecaptcha.ready(function() {
            grecaptcha
                .execute(process.env.KEY_RECAPTCHA_SITEKEY, {action: 'submit'})
                .then( (token) => {
                    resolve(token)
                })
        })
    })
}

const initRecaptcha = () => {
    return new Promise(function(resolve, reject) {
        let head = document.getElementsByTagName('head')[0]
        let script = document.createElement('script')
        script.type = 'text/javascript'
        script.addEventListener('load', function() {
            resolve(script)
        })
        script.src = 'https://www.google.com/recaptcha/api.js?render='+process.env.KEY_RECAPTCHA_SITEKEY
        head.appendChild(script)
    })
}

const bindFieldsEvents = () => {
    document.addEventListener('keyup', (event) => {
        if (
            event.target.parentElement.matches('.field__group')
            && event.target.matches('input, textarea')
        ) {
            const input = event.target
            if (input.value !== '') {
                input.classList.add('is-filled')
            } else {
                input.classList.remove('is-filled')
            }
        }
    })
    // const fields = form.querySelectorAll('.gfield')
    // for (const field of fields) {
    //     const input = field.querySelector('input, textarea')
    //     if (!input) return
    //     input.addEventListener('focus', () => {
    //         field.classList.add('field-focused')
    //     })
    //     input.addEventListener('blur', () => {
    //         field.classList.remove('field-focused')
    //         if (input.value !== '') {
    //             field.classList.add('field-filled')
    //         } else {
    //             field.classList.remove('field-filled')
    //         }
    //     })
    //     if (input.value !== '') {
    //         field.classList.add('field-filled')
    //     }
    //     field.classList.add('field-binded')
    // }
}

/**
 * Init
 */
export const init = () => {
    bindAjaxForm()
    bindFieldsEvents()
}

/**
 * Update
 */
export const update = () => {
    bindAjaxForm()
}