import { get } from "ThemeSrc/js/api/api";

export const URLS = {
    get: (id) => `/api/product/get/${id}`,
    getAll: () => `/api/product/get/all`
}

export async function fetchProduct (id) {
    return await get(URLS.get(id))
}

export async function fetchProducts () {
    return await get(URLS.getAll())
}