import Highway from '@dogstudio/highway';
import {SMOOTH_SCROLL} from "ThemeSrc/js/utils/Scroll";
import {menuApp} from "ThemeSrc/js/components";
import {maskIn, maskOut} from "ThemeSrc/js/navigation/utils/Mask";
import {updateLayout} from "ThemeSrc/js/components/Layout";

class BaseTransition extends Highway.Transition {

    constructor(wrap, name) {
        super(wrap, name)
    }

    out({ from, trigger, done }) {
        // console.log('base - out')


        const timeLine = maskIn()

        timeLine.call(() => {
            menuApp.close(true)
            done()
        }, null, '>')
    }

    in({ from, to, trigger, done }) {

        // reset scroll
        SMOOTH_SCROLL.scrollTo('top', {duration: 0})

        // clear if context exist
        const canvas = from.querySelector('#app-canvas')
        if (canvas && canvas.context) {
            canvas.context.curtains.dispose()
        }

        // remove old view
        from.remove()

        // console.log('base - in')

        const timeLine = maskOut()

        timeLine.call(() => {
            updateLayout()
        }, null, '>')

        timeLine.from(to, {
            opacity: 0,
            y: '25vh',
            duration: 1,
            ease: 'Power4.easeOut',
            onComplete: () => {
                to.style.transform = ''
            }
        },0.55)

        timeLine.call(() => {
            done()
        }, null, '>')
    }

}

export default BaseTransition;
