/**
 * Component
 * @name notificationComponent
 */
const OPTIONS = {
    type: 'infos',
    size: 'full',
    timeBeforeClose: 3000,
    class: {
        main: 'block-notif'
    }
}

export const clear = () => {
    let nodes = document.querySelectorAll('.' + OPTIONS.class.main)
    nodes.forEach( ( node ) => {
        node.remove()
    })
}

export const notify = ( text, params ) => {

    params = params || {};
    let options = {...OPTIONS, ...params};

    clear( options );

    let node = document.createElement('aside')
    node.classList.add(options.class.main, options.class.main+'-'+options.type,  options.class.main+'-'+options.size)
    let holder = document.createElement('div')
    holder.classList.add('holder')
    if (Array.isArray(text)) {
        text = text.join(' ')
    }
    holder.innerHTML = text
    node.appendChild(holder)
    document.body.append( node )

    setTimeout( () => {
        node.classList.add(options.class.main+'-opening')
    },10)

    handleClose( node, options )
}

const handleClose = ( node, options ) => {
    // animate close
    setTimeout( () => {
        node.classList.add(options.class.main+'-closing');
        node.classList.remove(options.class.main+'-opening')
    }, options.timeBeforeClose)
}

// export const Notification = () => {
//
//     // let triggerpopups = document.querySelectorAll('[data-popup]');
//     // if ( triggerpopups.length <= 0 ) return;
//     const OPTIONS = {
//         type: 'infos',
//         size: 'full',
//         timeBeforeClose: 3000,
//         class: {
//             main: 'block-notif'
//         }
//     }
//
//     const clear = ( options ) => {
//         let nodes = document.querySelectorAll('.'+options.class.main)
//         nodes.forEach( ( node ) => {
//             node.remove()
//         })
//     }
//
//     const handleClose = ( node, options ) => {
//         // animate close
//         setTimeout( () => {
//             node.classList.add(options.class.main+'-closing');
//             node.classList.remove(options.class.main+'-opening')
//         }, options.timeBeforeClose)
//     }
//
//     return {
//         create: ( text, params ) => {
//
//             params = params || {};
//             let options = {...OPTIONS, ...params};
//
//             clear( options );
//
//             let node = document.createElement('aside')
//             node.classList.add(options.class.main, options.class.main+'-'+options.type,  options.class.main+'-'+options.size)
//             let holder = document.createElement('div')
//             holder.classList.add('holder')
//             if (Array.isArray(text)) {
//                 text = text.join(' ')
//             }
//             holder.innerHTML = text
//             node.appendChild(holder)
//             document.body.append( node )
//
//             setTimeout( () => {
//                 node.classList.add(options.class.main+'-opening')
//             },10)
//
//             handleClose( node, options )
//         }
//     }
// }

// self.components = self.components || {}
// self.components.notification = Notification()
