import {gsap} from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import LocomotiveScroll from 'locomotive-scroll';
import {app} from "ThemeSrc/js/vue";

gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);

/**
 * Init locomotive scroll
 * @type {Smooth}
 */
export const SMOOTH_SCROLL = new LocomotiveScroll({
	el: document.querySelector('[data-scroll-container]'),
	smooth: true,
	passive: true,
	inertia: 0.95,
	getSpeed: true,
	// lerp: 0.25,,
	// reloadOnContextChange: true,
	getDirection: true
})
// window.sc= SMOOTH_SCROLL

SMOOTH_SCROLL.on('scroll', (scroll) => {
	app.$emit('SCROLL', {scroll})
})

app.$on('TOGGLE_MODAL', ({status}) => {
	status ? SMOOTH_SCROLL.stop() : SMOOTH_SCROLL.start()
})

SMOOTH_SCROLL.on('scroll', ScrollTrigger.update)

ScrollTrigger.scrollerProxy('[data-scroll-container]', {
	scrollTop(value) {
		return arguments.length ? SMOOTH_SCROLL.scrollTo(value, 0, 0) : SMOOTH_SCROLL.scroll.instance.scroll.y
	},
	getBoundingClientRect() {
		return {top: 0, left: 0, width: window.innerWidth, height: window.innerHeight}
	},
	pinType: document.querySelector('[data-scroll-container]').style.transform ? "transform" : "fixed"
})

ScrollTrigger.addEventListener('refresh', () => SMOOTH_SCROLL.update())

export const initActions = () =>
{
	document.addEventListener('click', (event) =>
	{
		if (
			event.target.matches('a[href^="#"]')
			&& !event.target.matches('a[href^="#plyr"]')
			&& !event.target.matches('a[href="#"]')
		) {
			event.preventDefault()
			event.stopPropagation()
			event.stopImmediatePropagation()

			SMOOTH_SCROLL.scrollTo(event.target.getAttribute('href'), {
				offset: -100
			})
		}
	})
}
