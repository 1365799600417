import {fetchProduct} from "ThemeSrc/js/api/product";

const state = {
    pageProduct: null
}

const getters = {

}

const actions = {
    async updatePageProduct ({ commit }, { id }) {
        await fetchProduct(id).then((product) => {
            commit('setPageProduct', { product })
        })
    },
    resetPageProduct ({ commit }) {
        commit('setPageProduct', { product : null })
    }
}

const mutations = {
    setPageProduct (state, { product }) {
        state.pageProduct = product
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
