<script>
	export default {
		name: 'Icon',
		props: {
			id: {
				type: String,
				required: true
			},
			className: {
				default: ''
			},
			viewBox: {
				default: false
			},
			width: {
				default: false
			},
			height: {
				default: false
			}

		},
		render(h, context) {

			const attributes = {
				class: `svgsprite-${this.id} ${this.className}`,
				width: this.width,
				height: this.height,
				viewBox: this.viewBox
			}

			const useAttributes = {
				'xlink:href': `${appjs.templateUrl}/svg/sprite.svg#${this.id}`
			}

			return (
				<svg {...{ attrs: attributes }} >
					<use {...{ attrs: useAttributes }}></use>
				</svg>
			)
		}
	}
</script>