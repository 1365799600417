import Highway from '@dogstudio/highway';
import {maskOut} from "ThemeSrc/js/navigation/utils/Mask";
import {setPageAnimation} from "ThemeSrc/js/navigation/utils/Page";

const updateImageSizeRule = function ()
{
    let images = document.querySelectorAll('img[sizes]')
    images.forEach((img) => {
        let vw = img.clientWidth;
        img.setAttribute('sizes', `(max-width: ${vw}px) 100vw, ${vw}px`)
    })
}

class BaseRenderer extends Highway.Renderer
{
    onEnter()
    {
        /**
         * Animation
         *
         * @name Mask
         */
        const timeLine = maskOut()

        /**
         * Animation
         *
         * @name Header
         */
        const header = document.querySelector('#header')

        if (header)
        {
            const headerRect = header.getBoundingClientRect()
            header.classList.add('is-animated')

            if (parseInt(header.style.top) !== 0)
            {
                timeLine.set(header, {visibility: 'visible', top: -(headerRect.height)}, 0)
            }

            timeLine.to(header, {
                top: 0,
                ease: 'Power4.easeOut',
                duration: 1.5,
                onComplete: () => {
                    header.classList.remove('is-animated')
                }
            }, 0)
        }


        timeLine.addLabel('pageReady', header ? '>-1.0' : 0.1)

        /**
         * Register event
         *
         * @event Update images rules
         */
        window.addEventListener('resize', updateImageSizeRule)
        updateImageSizeRule()


        /**
         * Animation
         *
         * @name Pages items
         */
        setPageAnimation(timeLine, 'pageReady')
    }
    onLeave() {
        /**
         * Remove event
         *
         * @event Update images rules
         */
        window.removeEventListener('resize', updateImageSizeRule)
    }
    // onEnterCompleted() { [...] }
    // onLeaveCompleted() { [...] }
}

export default BaseRenderer;
