/**
 * Lib
 * @name Grid
 */

export const GRID_GUTTER = 50

export const GRID_SIZES = [
    {
        size: 'xl',
        value: 2560
    },
    {
        size: 'lg',
        value: 1920
    },
    {
        size: 'md',
        value: 1440
    },
    {
        size: 'm',
        value: 1280
    },
    {
        size: 'sm',
        value: 1024
    },
    {
        size: 's',
        value: 768
    },
    {
        size: 'xs',
        value: 426
    },
    {
        size: 'xxs',
        value: 0
    }
]

export const CONTAINER_SIZES = [
    {
        size: 'sm',
        value: 540
    },
    {
        size: 'md',
        value: 720
    },
    {
        size: 'lg',
        value: 960
    },
    {
        size: 'xl',
        value: 1140
    }
]

/**
 * @param {string} maxSizeKey
 * @param {string|null} minSizeKey
 * @return boolean
 */
export const matchSize = ( maxSizeKey, minSizeKey = null) => {
    const maxSize = GRID_SIZES.find(s => s.size === maxSizeKey)
    const minSize = GRID_SIZES.find(s => s.size === minSizeKey)
    return window.innerWidth < maxSize.value && window.innerWidth > (minSize ? minSize.value : 0)
}
/**
 * @param {string} minSizeKey
 * @return boolean
 */
export const minSize = ( minSizeKey) => {
    const minSize = GRID_SIZES.find(s => s.size === minSizeKey)
    return window.innerWidth >= minSize.value
}
/**
 * @param {string} maxSizeKey
 * @return boolean
 */
export const maxSize = ( maxSizeKey) => {
    const maxSize = GRID_SIZES.find(s => s.size === maxSizeKey)
    return window.innerWidth < maxSize.value
}
/**
 * @param {string|null} size
 * @return number
 */
export const getContainerSize = (size) => {
    const currentSize = size ? size : getCurrentSize()
    return CONTAINER_SIZES.find(s => s.size === currentSize).value
}
/**
 * @return string
 */
export const getCurrentSize = () => {
    return GRID_SIZES.find(s => window.innerWidth >= s.value).size
}
/**
 * @return number
 */
export const getGridGutter = () => {
    return GRID_GUTTER
}