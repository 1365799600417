import gsap from "gsap";
import {menuApp} from "ThemeSrc/js/components";

export const getMask = () =>
{
    const mask = document.querySelector('#navigation-mask')
    return mask ? mask : createMask()
}

export const createMask = () =>
{
    const mask = document.createElement('div')
    mask.id = 'navigation-mask'
    document.body.appendChild(mask)
    return mask
}

export const maskOut = () =>
{
    const timeLine = gsap.timeline()
    const mask = getMask()

    timeLine.set(mask, {
        bottom: 'initial',
        top: 0,
        height: '100%'
    }, 0)

    timeLine.to(mask, {
        height: 0,
        duration: 0.75,
        ease: 'Power3.easeOut'
    }, 0)

    return timeLine
}

export const maskIn = () =>
{
    const timeLine = gsap.timeline()
    const mask = getMask()

    timeLine.set(mask, {
        top: 'initial',
        bottom: 0,
        height: 0,
    }, 0)

    timeLine.to(mask, {
        height: '100%',
        duration: 0.75,
        ease: 'Power3.easeIn'
    }, 0)

    return timeLine
}