/**
 * Component Class
 * @name Observer
 */
export class Observer {

	constructor() {

		this.status = true

		if (typeof IntersectionObserver === 'undefined') {
			this.status = false
			return
		}

		this.observed = []
		this.observer = window.appObserver ? window.appObserver : null
		if (!this.observer) this.initObserver()

		// if (this.observed) this.observe(this.observed)

		return this
	}

	/**
	 * @param node HTMLElement
	 * @param {function} intersect
	 */
	observe(node, intersect = null) {
		this.observed.push({
			node: node,
			intersect: intersect
			// do: doFunction,
			// undo: undoFunction,
		})
		this.observer.observe(node)
	}

	initObserver() {
		const intersectionOptions = {
			root: null,
			rootMargin: '0px 0px 0px 0px',
			threshold: this.buildThresholdList(100)
		}
		this.observer = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				let direction = '';
				let context = '';
				let previousY = entry.target.previousY || 0
				let previousRatio = entry.target.previousRatio || 0
				const currentY = entry.boundingClientRect.y
				const currentRatio = entry.intersectionRatio
				const isIntersecting = entry.isIntersecting

				if (currentY < previousY) {
					if (currentRatio > previousRatio && isIntersecting) {
						context = 'enter'
					} else {
						context = 'leave'
					}
					direction = 'down'
				} else if (currentY > previousY && isIntersecting) {
					if (currentRatio < previousRatio) {
						context = 'leave'
					} else {
						context = 'enter'
					}
					direction = 'up'
				}

				if (entry.intersectionRatio >= 0 && entry.intersectionRatio <= 1) {
					this.getNodeData(entry.target).intersect(entry.intersectionRatio)
				}

				if (direction === 'up') {
					entry.target.classList.add('observer-direction-up')
					entry.target.classList.remove('observer-direction-down')
				} else if (direction === 'down') {
					entry.target.classList.add('observer-direction-down')
					entry.target.classList.remove('observer-direction-up')
				} else {
					entry.target.classList.remove('observer-direction-up', 'observer-direction-down')
				}

				if (context === 'leave') {
					entry.target.classList.add('observer-context-leave')
					entry.target.classList.remove('observer-context-enter')
				} else if (context === 'enter') {
					entry.target.classList.add('observer-context-enter')
					entry.target.classList.remove('observer-context-leave')
				} else {
					entry.target.classList.remove('observer-context-leave', 'observer-context-enter')
				}

				if (entry.intersectionRatio >= 1) {
					entry.target.classList.add('observer-visible-full')
					entry.target.classList.remove('observer-visible-partial')
				} else if (entry.intersectionRatio > 0) {
					entry.target.classList.add('observer-visible-partial')
					entry.target.classList.remove('observer-visible-full')
				} else {
					entry.target.classList.remove('observer-visible-full', 'observer-visible-partial')
				}

				entry.target.previousY = currentY
				entry.target.previousRatio = currentRatio
			});
		}, intersectionOptions);
		// window.appObserver = this.observer
	}

	buildThresholdList(numSteps) {
		numSteps = numSteps || 100
		let thresholds = [];
		for (let i=0; i<=numSteps; i++) {
			let ratio = i/numSteps
			thresholds.push(ratio)
		}
		return thresholds;
	}

	clearObserved() {
		this.observed.forEach((data, index) => {
			this.observer.unobserve(data.node)
			// this.observed.splice(index, 1)
		})
		this.observed = []
	}

	getNodeData(target) {
		return this.observed.find((data) => data.node === target)
	}
}