const EVENT_NAMESPACE = process.env.APP_NAMESPACE
/**
 * Track event click tel
 * @param {string} place
 */
export const trackShowTelEvent = (place= 'undefined') => {
    if(typeof dataLayer === 'object' && dataLayer) {
        dataLayer.push({'event': `${EVENT_NAMESPACE}_customEvent`, parameters: {name: 'showPhoneNumber', href: location.href, place: place} });
    }
}
/**
 * Track show contact
 * @param {string} place
 * @param {string} wording
 */
export const trackShowContact = (place= 'undefined', wording  = 'undefined') => {
    if(typeof dataLayer === 'object' && dataLayer) {
        dataLayer.push({'event': `${EVENT_NAMESPACE}_customEvent`, parameters: {name: 'showContactPanel', href: location.href, place: place, wording: wording} });
    }
}
/**
 * Track send lead
 * @param {boolean} status
 * @param {string} subject
 * @param {string} message
 */
export const trackSendLead = (status, subject, message) => {
    console.log(status, subject, message)
    if(typeof dataLayer === 'object' && dataLayer) {
        dataLayer.push({'event': `${EVENT_NAMESPACE}_customEvent`, parameters: {name: 'sendLead', href: location.href, subject: subject, status: status, message: message} });
    }
}