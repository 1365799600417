import Highway from '@dogstudio/highway'
import BaseTransition from "ThemeSrc/js/navigation/transitions/BaseTransition";
import BaseRenderer from "ThemeSrc/js/navigation/renderers/BaseRenderer";
import {initBlocks} from "ThemeSrc/js/blocks";
import {updateLayout, updateLayoutItems} from "ThemeSrc/js/components/Layout";
import {updateBreadcrumb} from "ThemeSrc/js/navigation/utils/Breadcrumb";
import {updateBodyClass} from "ThemeSrc/js/navigation/utils/Body";
import {updateComponents} from "ThemeSrc/js/components";
import {app} from "ThemeSrc/js/vue";
import {SMOOTH_SCROLL} from "ThemeSrc/js/utils/Scroll";

export const H = new Highway.Core({
	renderers: {
		base: BaseRenderer
	},
	transitions: {
		default: BaseTransition
	}
})

H.on('NAVIGATE_OUT', ({ from, trigger, location }) =>
{
	document.body.classList.add('is-navigating')

	app.$emit('NAVIGATE_OUT')

})

H.on('NAVIGATE_IN', ({ to, trigger, location }) =>
{
	updateLayoutItems()
	updateComponents()

	updateBodyClass(to)
	document.body.classList.add('is-navigating')

	app.$emit('NAVIGATE_IN')

	/**
	 * Handle breadcrumb
	 */
	updateBreadcrumb(to)
})

H.on('NAVIGATE_END', ({ to, from, trigger, location }) =>
{
	initBlocks()

	document.body.classList.remove('is-navigating')

	app.$emit('NAVIGATE_END')

	SMOOTH_SCROLL.update()
	setTimeout(() => {
		window.dispatchEvent(new Event('resize'));
	}, 100)

	// console.log('set event', window.scrollX)

	/**
	 * Handle admin bar links
	 */
	const adminBar = document.querySelector('#wpadminbar')
	if (adminBar)
	{
		const newAdminBar = to.page.body.querySelector('#wpadminbar')
		adminBar.innerHTML = newAdminBar.innerHTML
		const adminBarLinks = document.querySelectorAll('#wpadminbar a')
		H.detach(adminBarLinks)
	}

	/**
	 * Detach anchors
	 */
	detachAnchors()
})

const detachAnchors = () => {
	const anchors = document.querySelectorAll('a[href^="#"], a[href^="#plyr"], a[href="#"]')
	H.detach(anchors)
}

const adminBarLinks = document.querySelectorAll('#wpadminbar a')
H.detach(adminBarLinks)
detachAnchors()
