import {gsap} from "gsap";
import {getGridGutter, matchSize} from "ThemeSrc/js/utils/Grid";
import {CSSRulePlugin} from 'gsap/CSSRulePlugin'
import {SMOOTH_SCROLL} from "ThemeSrc/js/utils/Scroll";
gsap.registerPlugin(CSSRulePlugin);

/**
 * Component Class
 * @name Menu
 */
export default class Menu {

    constructor(selector) {

        // main nodes
        this.selector = selector
        this.wrapper = document.querySelector(selector)
        this.menu = null
        this.mainNav = null
        this.itemHasSecondary = null

        // status/flag
        this.interval = null
        this.currentSecondaryMenu = null
        this.currentHoverMenu = null

        if (this.wrapper) this.init()
    }

    init() {

        this.rootNav = this.wrapper.querySelector('#menu-primary')
        this.mainNav = this.wrapper.querySelector('#menu-primary__root')

        this.itemHasSecondary = [...this.wrapper.querySelectorAll('[data-has-secondary]')].map((item) => {
            const menuId = parseInt(item.dataset.menuId)
            return {
                node: item,
                secondaryId: menuId,
                menu: this.wrapper.querySelector(`[data-menu-secondary="${menuId}"]`),
                image: this.wrapper.querySelector(`[data-menu-image="${menuId}"]`)
            }
        })

        this.itemHasImage = [...this.wrapper.querySelectorAll('[data-has-image]')].map((item) => {
            const menuId = parseInt(item.dataset.menuId)
            return {
                node: item,
                secondaryId: menuId,
                menu: this.wrapper.querySelector(`[data-menu-secondary="${menuId}"]`),
                image: this.wrapper.querySelector(`[data-menu-image="${menuId}"]`)
            }
        })

        this.debug()
        this.prepare()
        this.bindEvents()
    }

    debug() {
        // window.w = CSSRulePlugin
        // this.currentTab = this.tabs[0]
        // this.openTab()
        // const firstImage = this.itemHasImage[0]
        // if (!firstImage) return
        // this.showImage(firstImage.dataset.menuId)
        // this.firstNavItem = this.left.querySelector('.main-menu__item:first-child')
    }

    prepare() {
        this.prepareMenu()
    }

    bindEvents() {

        for (const itemHas of this.itemHasSecondary) {
            itemHas.node.addEventListener('click', async (event) => {
                event.preventDefault()
                if (this.currentSecondaryMenu === itemHas) return
                if (this.currentSecondaryMenu) await this.closeSubMenu()
                this.currentSecondaryMenu = itemHas
                this.openSubMenu()
            })
        }

        for (const itemHas of this.itemHasImage) {
            itemHas.node.addEventListener('mouseenter', (event) => {
                // if (!this.wrapper.classList.contains('is-ready')) return
                if (this.currentHoverMenu !== itemHas) {
                    this.hideImage(this.currentHoverMenu)
                }
                this.currentHoverMenu = itemHas
                this.currentHoverMenu.hover = true
                this.showImage()
            })
            itemHas.node.addEventListener('mousemove', (event) => {
                // if (!this.wrapper.classList.contains('is-ready')) return
                if (this.currentHoverMenu !== itemHas) {
                    this.currentHoverMenu = itemHas
                    this.showImage()
                }
                clearTimeout(this.interval)
                this.interval = setTimeout(() => {
                    if (!this.currentHoverMenu || !this.currentHoverMenu.hover) this.hideImage(this.currentHoverMenu)
                }, 200)
                this.moveImage(event)
            })
            itemHas.node.addEventListener('mouseout', (event) => {
                this.currentHoverMenu.hover = false
            })
        }

        document.addEventListener('click', (event) => {
            if (event.target.closest('[data-menu-open]')) {
                event.preventDefault()
                this.open()
            }
        })

        document.addEventListener('click', (event) => {
            if (event.target.closest('[data-menu-close]')) {
                event.preventDefault()
                this.close()
            }
        })

        document.addEventListener('click', (event) => {
            if (event.target.closest('[data-submenu-close]')) {
                event.preventDefault()
                if (this.currentSecondaryMenu) this.closeSubMenu()
            }
        })
    }
    
    open() {
        SMOOTH_SCROLL.stop()
        this.menu.open()
    }
    
    close(force) {
        if (!this.menu) return
        SMOOTH_SCROLL.start()
        this.hideImage(this.currentHoverMenu)
        this.closeSubMenu()
        force ? this.menu.reset() : this.menu.close()
    }

    prepareMenu() {

        const timeLine = gsap.timeline({
            paused: true,
            onStart: () => {
                this.wrapper.classList.add('is-open')
                document.body.classList.add('menu-open')
            },
            onComplete: () => {
                this.wrapper.classList.add('is-ready')
                this.prepareImages()
            },
            onReverseComplete: () => {
                this.wrapper.classList.remove('is-ready')
                this.wrapper.classList.remove('is-open')
                document.body.classList.remove('menu-open')
            }
        })

        // animate wrapper
        timeLine.fromTo(this.wrapper, {
            width: 0
        },{
            width: '100%',
            ease: 'Expo.easeOut',
            duration: 1
        }, 0)

        // animate mask
        const mask = this.wrapper.querySelector('#menu-wrapper--mask')
        timeLine.to(mask, {
            width: 0,
            ease: matchSize('sm') ? 'Expo.easeOut' : 'Expo.easeInOut',
            duration: 1,
            delay: .35
        }, 0)

        // position end wrapper
        timeLine.addLabel('endWrapper', '>')

        // animate menu items
        const partMenuItemsToShow = this.wrapper.querySelectorAll('#menu-primary__root .menu-item')
        timeLine.addLabel('startMenuItems', 'endWrapper-=0.25')
        timeLine.from(partMenuItemsToShow, {
            opacity: 0,
            xPercent: -20,
            ease: 'Expo.easeOut',
            duration: 0.75,
            stagger: {
                each: 0.055
            }
        }, 'startMenuItems')

        // animate secondary menu items
        const partSecondaryMenuItemsToShow = this.wrapper.querySelectorAll('#menu-secondary .menu-item')
        timeLine.addLabel('startMenuItems', 'endWrapper-=0.25')
        timeLine.from(partSecondaryMenuItemsToShow, {
            opacity: 0,
            ease: 'Expo.easeOut',
            duration: 0.75,
            stagger: {
                each: 0.055
            }
        }, 'startMenuItems')

        // animate social block
        const partSocial = this.wrapper.querySelector('#menu-social__holder')
        const partSocialToShow = this.wrapper.querySelectorAll('#menu-social__holder .to-show')
        timeLine.addLabel('startSocial', 'endWrapper-=0.25')
        timeLine.from(partSocial, {
            width: 0,
            xPercent: -100,
            ease: 'Expo.easeOut',
            duration: 0.75
        }, 'startSocial')
        timeLine.from(partSocialToShow, {
            opacity: 0,
            xPercent: -20,
            ease: 'Expo.easeOut',
            duration: 0.75,
            stagger: {
                each: 0.055
            }
        }, 'startSocial+=0.5')

        this.menu = {
            open: () => {
                timeLine.timeScale(1)
                timeLine.play()
            },
            close: () => {
                timeLine.timeScale(2)
                timeLine.reverse()
            },
            reset: () => {
                timeLine.pause()
                timeLine.time(0)
            }
        }
    }

    prepareImages() {
        for (const itemHas of this.itemHasImage) {
            this.moveImage(null, itemHas, 0)
        }
    }

    moveImage(event, menu, duration = null) {

        menu = menu || this.currentHoverMenu

        if (!menu || !menu.image) return

        const imageRect = menu.image.getBoundingClientRect()
        const itemRect = menu.node.getBoundingClientRect()
        const rootNavRect = this.rootNav.getBoundingClientRect()
        const x = event ? event.clientX : (itemRect.x + (itemRect.width / 2))
        const y = event ? event.clientY : (itemRect.y + (itemRect.height / 2))
        gsap.to(menu.image, {
            x: x - rootNavRect.x - (imageRect.width / 2),
            y: y - rootNavRect.y - (imageRect.height / 2),
            ease: 'Expo.ease',
            delay: 0.1,
            duration: duration
        })
    }

    showImage() {

        if (!this.currentHoverMenu || !this.currentHoverMenu.image) return

        this.currentHoverMenu.image.classList.add('is-visible')
        gsap.to(this.currentHoverMenu.image, {
            opacity: .2,
            ease: 'Expo.ease',
            delay: 0.1
        })
    }

    hideImage(menu) {

        menu = menu || this.currentHoverMenu

        if (!menu || !menu.image) return

        this.currentHoverMenu.image.classList.remove('is-visible')
        this.interval = null
        gsap.to(menu.image, {
            opacity: 0
        })
    }

    openSubMenu() {

        this.currentSecondaryMenu.node.classList.add('is-open')
        this.currentSecondaryMenu.menu.classList.add('is-open')

        const nodeRect = this.currentSecondaryMenu.node.getBoundingClientRect()
        const secondaryItems = this.currentSecondaryMenu.menu.querySelectorAll('.menu-item')
        const timeLine = gsap.timeline()

        if (matchSize('sm'))
        {
            const menuRect = this.currentSecondaryMenu.menu.getBoundingClientRect()
            timeLine.to(this.rootNav, {
                height: menuRect.height,
                duration: .3,
                ease: 'Power4.easeOut'
            }, 0)
            timeLine.to(this.mainNav, {
                opacity: 0,
                duration: .3
            }, 0)
            timeLine.to(this.currentSecondaryMenu.menu, {
                opacity: 1,
                duration: .3
            }, 0)
        }
        else
        {
            timeLine.set(this.currentSecondaryMenu.menu, {
                y: nodeRect.y - (getGridGutter() / 2) + 15
            }, 0)

            timeLine.set(secondaryItems, {
                x: -30,
                opacity: 0
            }, 0)

            timeLine.to(secondaryItems, {
                opacity: 1,
                x: 0,
                stagger: {
                    from: 'top',
                    each: 0.055
                },
                ease: 'Power3.easeInOut',
                duration: 1.3
            }, 0)

            timeLine.fromTo(this.currentSecondaryMenu.node, {
                '--arrow-opacity': 1,
                '--arrow-translate-x': '0',
                '--line-width': '0',
            }, {
                '--arrow-opacity': 0,
                '--arrow-translate-x': '-30px',
                '--line-width': '90px',
                ease: 'Power3.easeInOut',
                duration: 0.75
            }, 0)
        }

        // if (matchSize('md')) {
        //     this.mainNav.oldHeight = this.mainNav.scrollHeight
        //     gsap.to(this.mainNav, {
        //         height: this.currentSecondaryMenu.menu.scrollHeight,
        //         opacity: 0,
        //         duration: .5,
        //         ease: 'Expo.easeOut',
        //     })
        // }
    }

    closeSubMenu() {

        return new Promise((resolve) =>
        {
            if (!this.currentSecondaryMenu) return resolve()

            const oldSecondary = this.currentSecondaryMenu
            oldSecondary.node.classList.remove('is-open')

            const secondaryItems = oldSecondary.menu.querySelectorAll('.menu-item')
            const timeLine = gsap.timeline()

            if (matchSize('sm'))
            {
                const rootRect = this.mainNav.getBoundingClientRect()
                timeLine.to(this.rootNav, {
                    height: rootRect.height,
                    duration: .3,
                    ease: 'Power4.easeOut'
                }, 0)
                timeLine.to(this.mainNav, {
                    opacity: 1,
                    duration: .3
                }, 0)
                timeLine.to(oldSecondary.menu, {
                    opacity: 0,
                    duration: .3
                }, 0)
            }
            else
            {
                timeLine.to(secondaryItems, {
                    opacity: 0,
                    x: 30,
                    stagger: {
                        from: 'end',
                        each: 0.06
                    },
                    ease: 'Power3.easeInOut',
                    duration: 0.6
                }, 0)

                timeLine.fromTo(this.currentSecondaryMenu.node, {
                    '--arrow-opacity': 0,
                    '--arrow-translate-x': '15px',
                    '--line-width': '90px',
                }, {
                    '--arrow-opacity': 1,
                    '--arrow-translate-x': '0px',
                    '--line-width': '0px',
                    ease: 'Power3.easeInOut',
                    duration: 0.7
                }, 0)
            }

            timeLine.eventCallback('onComplete', () => {
                oldSecondary.menu.classList.remove('is-open')
                this.currentSecondaryMenu = null
                if (matchSize('sm'))
                {
                    this.rootNav.style.height = ''
                }
                resolve()
            })
        })

        // if (matchSize('md')) {
        //     gsap.to(this.mainNav, {
        //         height: this.mainNav.oldHeight,
        //         opacity: 1,
        //         duration: .5,
        //         ease: 'Expo.easeOut',
        //         onComplete: () => {
        //             this.mainNav.style.height = ''
        //         }
        //     })
        // }
    }
}