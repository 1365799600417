/**
 * @param nodes
 * @returns {HTMLDivElement}
 */
export const wrapAll = nodes => {
    const el = nodes.length ? nodes[0] : nodes;
    const wrapper = document.createElement('div')

    const parent  = el.parentNode;
    const sibling = el.nextSibling;

    wrapper.appendChild(el);

    while (nodes.length) {
        wrapper.appendChild(nodes[0]);
    }

    if (sibling) {
        parent.insertBefore(wrapper, sibling);
    } else {
        parent.appendChild(wrapper);
    }

    return wrapper
}

/**
 * @param name
 * @returns {string}
 */
export const getCssVarValue = name => {
    return getComputedStyle(document.body).getPropertyValue(name)
}

/**
 * @param object
 * @returns {FormData}
 */
export const parseFormData = (object = {}) => {

    const formData = new FormData()

    for (let key in object)
    {
        const objectValue = object[key]

        if (objectValue instanceof File)
        {
            formData.append(key, objectValue)
        }
        else if (typeof objectValue === 'object')
        {
            if (objectValue && Object.keys(objectValue).length > 0)
            {
                formData.append(key, JSON.stringify(objectValue))
            }
        }
        else
        {
            formData.append(key, objectValue)
        }
    }

    return formData
}